<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICA DEL SISTEMA PARA LOS ACTIVOS DE INFORMACIÓN</span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de activos de información</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('005-27001-ACT','c')==1" cols="4" class="my-2">
                            <b-button block @click="modalRegistrarActivo = true" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo activo
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Activos de información registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaActivos" :fields="campoEquipos" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(clasificacion)="param">
                                    <span v-if="param.item.clasificacion==1">Pública</span>
                                    <span v-else-if="param.item.clasificacion==2">Interna</span>
                                    <span v-else-if="param.item.clasificacion==3">Confidencial</span>
                                    <span v-else-if="param.item.clasificacion==4">Restringida</span>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('005-27001-ACT','u')==1" @click="abrirModalActualizarActivo(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('005-27001-ACT','d')==1" @click="eliminarActivo(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(nivelTasacion)="param">
                                    <span v-if="param.item.nivelTasacion<=4"><i class="fas fa-circle text-success"></i> Bajo</span>
                                    <span v-else-if="param.item.nivelTasacion>=5 && param.item.nivelTasacion<=7"><i class="fas fa-circle text-warning"></i> Medio</span>
                                    <span v-else-if="param.item.nivelTasacion>=8"><i class="fas fa-circle text-danger"></i> Alto</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalRegistrarActivo">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo activo </span>
            </h6>
            <CButtonClose @click="modalRegistrarActivo = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarActivo)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="activo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Activo:" class="mb-2">
                                <b-form-input type="text" v-model="datosActivo.activo" :state="getValidationState(validationContext)" placeholder="Ingrese el activo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="serie" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Serie:" class="mb-2">
                                <b-form-input type="text" v-model="datosActivo.serie" :state="getValidationState(validationContext)" placeholder="Ingrese la serie"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="categoría" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Categoría:" class="mb-2">
                                <b-form-select :options="comboCategorias" v-model="datosActivo.categoria" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="tipo de activo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo de activo:" class="mb-2">
                                <b-form-input type="text" v-model="datosActivo.tipoActivo" :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de activo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="tipo de ubicación" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Tipo de ubicación:" class="mb-2">
                                <b-form-select :options="comboTipoUbicacion" v-model="datosActivo.tipoUbicacion" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3" v-if="datosActivo.tipoUbicacion==1">
                        <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Ubicación:" class="mb-2">
                                <b-form-input type="text" v-model="datosActivo.ubicacion" :state="getValidationState(validationContext)" placeholder="Ingrese la ubicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="clasificación" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Clasificación:" class="mb-2">
                                <b-form-select :options="comboClasificacion" v-model="datosActivo.clasificacion" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="frecuencia de uso" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Frecuencia de uso:" class="mb-2">
                                <b-form-select :options="comboFrecuenciaUso" v-model="datosActivo.frecuenciaUso" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="propietario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Propietario:" class="mb-2">
                                <b-form-input type="text" v-model="datosActivo.propietario" :state="getValidationState(validationContext)" placeholder="Ingrese el propietario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="Responsable de custodia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Responsable de custodia:" class="mb-2">
                                <b-form-input type="text" v-model="datosActivo.responsableCustodia" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="confidencialidad" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Confidencialidad:" class="mb-2">
                                <b-form-select :options="comboValorActivo" @change="calcularNivelTasacion()" v-model="datosActivo.confidencialidad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="integridad" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Integridad:" class="mb-2">
                                <b-form-select :options="comboValorActivo" @change="calcularNivelTasacion()" v-model="datosActivo.integridad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="disponibilidad" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Disponibilidad:" class="mb-2">
                                <b-form-select :options="comboValorActivo" @change="calcularNivelTasacion()" v-model="datosActivo.disponibilidad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="valor del activo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Valor del activo:" class="mb-2">
                                <b-form-select :options="comboValorActivo" @change="calcularNivelTasacion()" v-model="datosActivo.valorActivo" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="nivel de tasación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nivel de tasación:" class="mb-2">
                                <b-input-group>
                                  <b-input-group-prepend>
                                        <b-input-group-text :style="`background:${datosActivo.nivelTasacionTexto == 'Alto' ? '#e55353;' : datosActivo.nivelTasacionTexto == 'Medio' ? '#f9b115;' : datosActivo.nivelTasacionTexto == 'Bajo' ? '#2eb85c;' : '' }`">
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input disabled class="disabled-white" v-model="datosActivo.nivelTasacionTexto" :state="getValidationState(validationContext)" placeholder="Tasación del activo"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosActivo.descripcion" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del activo"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarActivo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalActualizarActivo">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar activo</span></h6>
            <CButtonClose @click="modalActualizarActivo = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarActivo)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="activo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Activo:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarActivo.activo" :state="getValidationState(validationContext)" placeholder="Ingrese el activo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="serie" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Serie:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarActivo.serie" :state="getValidationState(validationContext)" placeholder="Ingrese la serie"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="categoría" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Categoría:" class="mb-2">
                                <b-form-select :options="comboCategorias" v-model="datosModificarActivo.categoria" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="tipo de activo" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Tipo de activo:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarActivo.tipoActivo" :state="getValidationState(validationContext)" placeholder="Ingrese el tipo de activo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="tipo de ubicación" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Tipo de ubicación:" class="mb-2">
                                <b-form-select :options="comboTipoUbicacion" v-model="datosModificarActivo.tipoUbicacion" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3" v-if="datosModificarActivo.tipoUbicacion==1">
                        <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Ubicación:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarActivo.ubicacion" :state="getValidationState(validationContext)" placeholder="Ingrese la ubicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="clasificación" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Clasificación:" class="mb-2">
                                <b-form-select :options="comboClasificacion" v-model="datosModificarActivo.clasificacion" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="frecuencia de uso" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Frecuencia de uso:" class="mb-2">
                                <b-form-select :options="comboFrecuenciaUso" v-model="datosModificarActivo.frecuenciaUso" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="propietario" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Propietario:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarActivo.propietario" :state="getValidationState(validationContext)" placeholder="Ingrese el propietario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="Responsable de custodia" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Responsable de custodia:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarActivo.responsableCustodia" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="confidencialidad" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Confidencialidad:" class="mb-2">
                                <b-form-select @change="calcularNivelTasacionActualizar()" :options="comboValorActivo" v-model="datosModificarActivo.confidencialidad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="integridad" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Integridad:" class="mb-2">
                                <b-form-select @change="calcularNivelTasacionActualizar()" :options="comboValorActivo" v-model="datosModificarActivo.integridad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="disponibilidad" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Disponibilidad:" class="mb-2">
                                <b-form-select @change="calcularNivelTasacionActualizar()" :options="comboValorActivo" v-model="datosModificarActivo.disponibilidad" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="valor del activo" :rules="{ required: true }" v-slot="validationContext">
                            <b-form-group label="Valor del activo:" class="mb-2">
                                <b-form-select @change="calcularNivelTasacionActualizar()" :options="comboValorActivo" v-model="datosModificarActivo.valorActivo" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="nivel de tasación" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nivel de tasación:" class="mb-2">
                                <b-input-group>
                                  <b-input-group-prepend>
                                        <b-input-group-text :style="`background:${datosModificarActivo.nivelTasacionTexto == 'Alto' ? '#e55353;' : datosModificarActivo.nivelTasacionTexto == 'Medio' ? '#f9b115;' : datosModificarActivo.nivelTasacionTexto == 'Bajo' ? '#2eb85c;' : '' }`">
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input disabled class="disabled-white" v-model="datosModificarActivo.nivelTasacionTexto" :state="getValidationState(validationContext)" placeholder="Tasación del activo"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosModificarActivo.descripcion" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del activo"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarActivo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import {
    CChartPie
} from '@coreui/vue-chartjs'
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            fechaActual: moment().format('YYYY-MM-DD'),
            labelEstadistica: ["Bajo", "Medio", "Alto"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            modalRegistrarActivo: false,
            modalActualizarActivo: false,
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            comboCargoResponsable: [],
            campoEquipos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "activo",
                    label: "Activo",
                    class: "text-center",
                },
                {
                    key: "serie",
                    label: "Serie",
                    class: "text-center",
                },
                {
                    key: "tipoActivo",
                    label: "Tipo de activo",
                    class: "text-center",
                },
                {
                    key: "clasificacion",
                    label: "Clasificacion",
                    class: "text-center",
                },
                {
                    key: "nivelTasacion",
                    label: "Nivel de tasación",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],

            comboTipoUbicacion: [{
                    value: 1,
                    text: 'Física'
                },
                {
                    value: 2,
                    text: 'Lógica'
                }
            ],
            comboClasificacion: [{
                    value: 1,
                    text: 'Pública'
                },
                {
                    value: 2,
                    text: 'Interna'
                },
                {
                    value: 3,
                    text: 'Confidencial'
                },
                {
                    value: 4,
                    text: 'Restringida'
                }
            ],
            comboFrecuenciaUso: [{
                    value: 4,
                    text: 'Muy frecuente'
                },
                {
                    value: 3,
                    text: 'Frecuente'
                },
                {
                    value: 2,
                    text: 'Poco frecuente'
                },
                {
                    value: 1,
                    text: 'Raro'
                }
            ],
            comboValorActivo: [{
                    value: 3,
                    text: 'Alto'
                },
                {
                    value: 2,
                    text: 'Medio'
                },
                {
                    value: 1,
                    text: 'Bajo'
                }
            ],
            comboCategorias: [{
                    value: 1,
                    text: 'Computadora'
                },
                {
                    value: 2,
                    text: 'Servidor local'
                },
                {
                    value: 3,
                    text: 'Nube'
                },
                {
                    value: 4,
                    text: 'Unidad de almacenamiento'
                },
                {
                    value: 5,
                    text: 'Legajo'
                },
            ],
            listaActivos: [],
            datosActivo: {
                idCliente: '',
                activo: '',
                serie: '',
                categoria: null,
                tipoActivo: '',
                tipoUbicacion: null,
                ubicacion: '',
                clasificacion: null,
                frecuenciaUso: null,
                propietario: '',
                responsableCustodia: '',
                confidencialidad: null,
                integridad: null,
                disponibilidad: null,
                valorActivo: null,
                nivelTasacion: '',
                nivelTasacionTexto: '',
                descripcion: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosModificarActivo: {
                idActivo: '',
                idCliente: '',
                activo: '',
                serie: '',
                categoria: null,
                tipoActivo: '',
                tipoUbicacion: null,
                ubicacion: '',
                clasificacion: null,
                frecuenciaUso: null,
                propietario: '',
                responsableCustodia: '',
                confidencialidad: null,
                integridad: null,
                disponibilidad: null,
                valorActivo: null,
                nivelTasacion: '',
                nivelTasacionTexto: '',
                descripcion: '',
                ejercicio: ''
            },
            datosSession: {
                idCliente: ''
            },
            disabled: false
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarActivosInformacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-activos-informacion-sgsi", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaActivos = response.data;
                    me.totalRows = me.listaActivos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        calcularNivelTasacion() {
            let me = this;
            let nivelT = parseInt(me.datosActivo.confidencialidad) + parseInt(me.datosActivo.integridad) + parseInt(me.datosActivo.disponibilidad);
            if(nivelT <= 4){
                me.datosActivo.nivelTasacionTexto = 'Bajo';
            }
            if(nivelT >= 5 && nivelT <= 7){
                me.datosActivo.nivelTasacionTexto = 'Medio';
            }
            if(nivelT >=8){
                me.datosActivo.nivelTasacionTexto = 'Alto';
            }
            me.datosActivo.nivelTasacion = nivelT;
            
        },
        calcularNivelTasacionActualizar() {
            let me = this;
            let nivelT = parseInt(me.datosModificarActivo.confidencialidad) + parseInt(me.datosModificarActivo.integridad) + parseInt(me.datosModificarActivo.disponibilidad);
            if(nivelT <= 4){
                me.datosModificarActivo.nivelTasacionTexto = 'Bajo';
            }
            if(nivelT >= 5 && nivelT <= 7){
                me.datosModificarActivo.nivelTasacionTexto = 'Medio';
            }
            if(nivelT >=8){
                me.datosModificarActivo.nivelTasacionTexto = 'Alto';
            }
            me.datosModificarActivo.nivelTasacion = nivelT;
        },
        registrarActivo() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-activo-sgsi",
                    me.datosActivo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarActivo = false;
                    me.listarActivosInformacion();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        modificarActivo() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-activo-sgsi",
                    me.datosModificarActivo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarActivo = false;
                    me.listarActivosInformacion();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
       
        resetModalRegistrarActivo() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosActivo.activo = '';
            this.datosActivo.serie = '';
            this.datosActivo.categoria = '';
            this.datosActivo.tipoActivo = '';
            this.datosActivo.tipoUbicacion = null;
            this.datosActivo.ubicacion = '';
            this.datosActivo.clasificacion = null;
            this.datosActivo.frecuenciaUso = null;
            this.datosActivo.propietario = '';
            this.datosActivo.responsableCustodia = '';
            this.datosActivo.confidencialidad = null;
            this.datosActivo.integridad = null;
            this.datosActivo.disponibilidad = null;
            this.datosActivo.valorActivo = null;
            this.datosActivo.nivelTasacion = '';
            this.datosActivo.descripcion = '';
            this.datosActivo.nivelTasacionTexto = '';
        },
        resetModalActualizarActivo() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
        abrirModalActualizarActivo(param) {
            let me = this;
            me.datosModificarActivo.idActivo = param.item.idActivo;
            me.datosModificarActivo.idCliente = param.item.idCliente;
            me.datosModificarActivo.activo = param.item.activo;
            me.datosModificarActivo.serie = param.item.serie;
            me.datosModificarActivo.categoria = param.item.categoria;
            me.datosModificarActivo.tipoActivo = param.item.tipoActivo;
            me.datosModificarActivo.tipoUbicacion = param.item.tipoUbicacion;
            me.datosModificarActivo.ubicacion = param.item.ubicacion;
            me.datosModificarActivo.clasificacion = param.item.clasificacion;
            me.datosModificarActivo.frecuenciaUso = param.item.frecuenciaUso;
            me.datosModificarActivo.propietario = param.item.propietario;
            me.datosModificarActivo.responsableCustodia = param.item.responsableCustodia;
            me.datosModificarActivo.confidencialidad = param.item.confidencialidad;
            me.datosModificarActivo.integridad = param.item.integridad;
            me.datosModificarActivo.disponibilidad = param.item.disponibilidad;
            me.datosModificarActivo.valorActivo = param.item.valorActivo;
            me.datosModificarActivo.nivelTasacion = param.item.nivelTasacion;
            me.datosModificarActivo.nivelTasacionTexto = me.datosModificarActivo.nivelTasacion <= 4 ? 'Bajo' : me.datosModificarActivo.nivelTasacion>=5 && me.datosModificarActivo.nivelTasacion<=7 ? 'Medio' : 'Alto';
            me.datosModificarActivo.descripcion = param.item.descripcion;
            me.datosModificarActivo.ejercicio = param.item.ejercicio;

            this.modalActualizarActivo = true;
        },

        eliminarActivo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el activo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-activo-sgsi", {
                            idActivo: param.item.idActivo,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarActivosInformacion();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        },
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,

                backgroundColor: [
                    'rgba(107,186,112,0.5)',
                    'rgba(248,148,6,0.5)',
                    'rgba(176,43,44,0.5)',
                ],
                data: [this.listaActivos.filter(x => x.nivelTasacion<=4).length, this.listaActivos.filter(x => x.nivelTasacion>=5 && x.nivelTasacion<=7).length, this.listaActivos.filter(x => x.nivelTasacion>=8).length]
            }]
        }
    },
    watch: {
        modalRegistrarActivo: function (val) {
            if (val == false) {
                this.resetModalRegistrarActivo();
            }
        },
        modalActualizarActivo: function (val) {
            if (val == false) {
                this.resetModalActualizarActivo();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = this.datosActivo.idCliente = user.uidClient;
            //this.listarEmpleados();
            //this.listarPuestosTrabajo();
            this.listarActivosInformacion();
        }
    }
}
</script>
